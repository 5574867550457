<template>
  <div class="sponsors-section" v-if="sponsorsList.length">
    <v-container>
      <div class="headingParagraph">
            Our Sponsors
            <div class="titleLine"></div>
          </div>
      <v-row align="center" >
        <!-- Left Section: Heading & Sponsor Logos -->
        <v-col cols="12" md="8">
         

          <v-row class="mt-4" dense>
            <v-col
              v-for="(item, i) in sponsorsList"
              :key="i"
              cols="6"
              sm="4"
              md="3"
              class="d-flex justify-center align-center"
            >
              <img
                class="sponsorImage"
                :src="item.sponsor_logo_url"
                height="auto"
                width="100%"
                alt="Sponsor Logo"
                @click="visitSponsorPage(item.website)"
              />
            </v-col>
          </v-row>
        </v-col>

        <!-- Right Section: Illustration -->
        <v-col cols="12" md="4" class="d-flex justify-center align-center mt-4 mt-md-0">
          <img
            class="illustration"
            src="@/assets/publicDonationPage/sponsors.png"
            alt="Sponsors Illustration"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Axios from "@/api/BaseAxios";
import { API_PUBLIC_GET_SPONSOR_LIST } from "@/constants/APIUrls";
import { mapGetters } from "vuex";
export default {
  name: "SponsorsDetail",
  data() {
    return {
      sponsorsList: [],
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
  },
  methods: {
    visitSponsorPage(url) {
      url = url.match(/^https?:/) ? url : "//" + url;
      window.open(url, "_blank");
    },
    getSponsorsDetail() {
      const successHandler = (res) => {
        this.sponsorsList = res.data.sponsor_list;
        // console.log(this.sponsorsList, "sponsors");
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      let formData = {};
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id;
      Axios.request_GET(
        API_PUBLIC_GET_SPONSOR_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSponsorsDetail();
    }, 50);
  },
};
</script>
<style scoped>
.sponsors-section {
  background-color: #f1f1f1;
  padding: 40px 0;
  place-content: center;
}

.headingParagraph {
  font-family: 'Roboto Slab', serif;
  font-weight: 600;
  font-size: 26px;
  color: #2c1963;
  display: flex;
  align-items: center;
  place-content: center;
}

.titleLine {
  height: 4px;
  width: 50px;
  background-color: #2c1963;
  border-radius: 20px;
  margin-left: 15px;
}

.sponsorImage {
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.sponsorImage:hover {
  transform: scale(1.05);
}

.illustration {
  width: 100%;
  max-width: 280px;
  object-fit: contain;
}

/* Mobile styles */
@media only screen and (max-width: 600px) {
  .headingParagraph {
    font-size: 20px;
  }

  .titleLine {
    width: 30px;
    height: 3px;
    margin-left: 10px;
  }

  .sponsorImage {
    max-width: 80px;
    max-height: 45px;
  }

  .illustration {
    max-width: 180px;
  }
}

</style>
